import { AgGridReact } from '@ag-grid-community/react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ColDef, ICellRendererParams } from '@ag-grid-community/core'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { debounce } from 'lodash'
import { hasMatch } from 'fzy.js'
import { Icon, Spinner, Text, TextInput, Tooltip } from 'src/components/ui'
import { zIndex } from 'src/utility/constants'
import { humanDateTime } from 'src/utility/time'
import { GqlOpcUaNode } from 'src/services'
import { isDefined } from 'src/types'
import { ErrorDisplay } from 'pages/app'
import { useOpcHierarchyNodes } from '../opc-ua-connection.api'
import { getHierarchyTreeConfig } from './opc-ua-hierarchy-tree'
import { getNodeIcon, useNodePaths } from './opc-ua-hierarchy.utils'

type Props = {
  connectionId: string
  siteId: string
}

export function OpcUaHierarchyTree({
  connectionId,
  siteId,
}: Props): JSX.Element {
  const connectionHierarchyQuery = useOpcHierarchyNodes(connectionId)

  // Fetch all pages of nodes
  useEffect(() => {
    if (
      connectionHierarchyQuery.hasNextPage &&
      !connectionHierarchyQuery.isFetching
    ) {
      connectionHierarchyQuery.fetchNextPage()
    }
  }, [connectionHierarchyQuery])

  if (
    connectionHierarchyQuery.isLoading ||
    connectionHierarchyQuery.isFetching ||
    connectionHierarchyQuery.hasNextPage
  )
    return <Spinner />

  if (connectionHierarchyQuery.isError) {
    return (
      <ErrorDisplay
        error={connectionHierarchyQuery.error}
        message="Failed to fetch hierarchy nodes!"
        action={connectionHierarchyQuery.refetch}
      />
    )
  }

  if (
    !connectionHierarchyQuery.hasNextPage &&
    !connectionHierarchyQuery.isFetching
  ) {
    const nodes = connectionHierarchyQuery.data?.pages
      .flatMap(page => page.items)
      .filter(isDefined)

    return <HierarchyTree treeData={nodes} siteId={siteId} />
  }

  return <></>
}

type TreeProps = {
  siteId: string
  treeData: GqlOpcUaNode[]
}

function HierarchyTree({ treeData }: TreeProps): JSX.Element {
  const [searchInput, setSearchInput] = useState<string>('')
  const searchInputRef = useRef<string>(searchInput)
  const gridRef = useRef<AgGridReact>(null)
  const nodeMap = useMemo(() => {
    return new Map(treeData.map(node => [node.id, { ...node }]))
  }, [treeData])

  const rootNode = treeData.find(node => node.extendedNodeId === 'i=85')
  const convertedNodes = useNodePaths(nodeMap, rootNode?.id ?? '')

  const convertedNodesArray = useMemo(
    () => [...convertedNodes.values()],
    [convertedNodes],
  )

  const errorNodes = useMemo(
    () => convertedNodesArray.filter(n => n.lastBrowseError).map(n => n.id),
    [convertedNodesArray],
  )

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      minWidth: 300,
      initialFlex: 1,
      sortable: false,
      resizable: true,
    }
  }, [])

  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      field: 'name',
      headerName: 'Nodes',
      flex: 1000,
      showRowGroup: false,
      suppressCount: true,
      filterValueGetter: params => {
        const nodeData = params.data as GqlOpcUaNode & { path: string[] }
        return nodeData.displayName ?? nodeData.browseName
      },
      cellRendererParams: {
        innerRenderer: (params: ICellRendererParams) => {
          const nodeData = params.data as GqlOpcUaNode & { path: string[] }
          const icon = getNodeIcon(
            nodeData.class,
            nodeData.typeDefinition ?? undefined,
          )
          const isErrorNode = errorNodes.includes(nodeData.id)
          const isChildOfErrorNode = nodeData.path
            .slice(0, nodeData.path.length - 1)
            .some((id: string) => errorNodes.includes(id))
          return (
            <div className="relative flex items-center gap-xs">
              {isErrorNode && (
                <Tooltip
                  zIndex={zIndex.modalLegendMenu}
                  direction="bottom"
                  render={() => {
                    const errorMessage =
                      nodeData.lastBrowseError ?? 'Unknown error'
                    const timestamp = nodeData.lastBrowsedAt
                      ? humanDateTime(nodeData.lastBrowsedAt)
                      : undefined
                    return (
                      <Text>
                        {timestamp
                          ? `${timestamp}: ${errorMessage}`
                          : errorMessage}
                      </Text>
                    )
                  }}
                >
                  <Icon
                    icon={light('circle-exclamation')}
                    className="text-icon-danger"
                    size="regular"
                  />
                </Tooltip>
              )}
              <Tooltip
                isOpen={isChildOfErrorNode ? undefined : false}
                zIndex={zIndex.modalLegendMenu}
                direction="bottom"
                render={() => (
                  <Text>
                    Node was last seen {humanDateTime(params.data.lastSeenAt)}
                  </Text>
                )}
              >
                <div className="flex items-center gap-xs">
                  {!isErrorNode && <Icon icon={icon} size="regular" />}
                  <Tooltip
                    direction="right"
                    isOpen={isChildOfErrorNode ? false : undefined}
                    zIndex={zIndex.modalLegendMenu}
                    render={() => nodeData.nodeId}
                  >
                    {nodeData.displayName ?? nodeData.browseName}
                  </Tooltip>
                </div>
              </Tooltip>
            </div>
          )
        },
      },
    }
  }, [errorNodes])

  // SEARCH FUNCTIONALITY
  useEffect(() => {
    searchInputRef.current = searchInput
  }, [searchInput])

  const expandOnSearch = useCallback(() => {
    if (gridRef.current) {
      // We need to manually open the parent nodes of the search results
      gridRef.current.api.forEachNodeAfterFilter(node => {
        const nodeName = node.data.displayName ?? node.data.browseName
        if (
          !nodeName.toLowerCase().includes(searchInputRef.current.toLowerCase())
        ) {
          node.setExpanded(true)
        }
      })
    }
  }, [])

  const doesFuzzyFilterPass = useCallback((node: any): boolean => {
    const pattern = searchInputRef.current.replaceAll(' ', '')
    const nodeData = node.data as GqlOpcUaNode
    if (!pattern || !(nodeData.displayName ?? nodeData.browseName)) return true

    const match = hasMatch(pattern, nodeData.displayName ?? nodeData.browseName)
    return match
  }, [])

  const isFuzzyFilterPresent = useCallback((): boolean => {
    return !!searchInputRef.current
  }, [])

  const debouncedFilterChange = useMemo(
    () =>
      debounce(() => {
        if (gridRef.current?.api) {
          gridRef.current.api.onFilterChanged()
          setTimeout(expandOnSearch, 200)
        }
      }, 500),
    [expandOnSearch],
  )

  useEffect(() => {
    debouncedFilterChange()
  }, [
    isFuzzyFilterPresent,
    doesFuzzyFilterPass,
    searchInput,
    debouncedFilterChange,
  ])

  const memoedTree = useMemo(() => {
    return (
      <AgGridReact
        ref={gridRef}
        enableCellTextSelection
        rowData={convertedNodesArray}
        treeData={true}
        rowModelType="clientSide"
        getDataPath={data => data.path}
        columnDefs={getHierarchyTreeConfig()}
        getRowId={data => data.data.id}
        defaultColDef={defaultColDef}
        autoGroupColumnDef={autoGroupColumnDef}
        isExternalFilterPresent={isFuzzyFilterPresent}
        doesExternalFilterPass={doesFuzzyFilterPass}
      />
    )
  }, [
    autoGroupColumnDef,
    convertedNodesArray,
    defaultColDef,
    doesFuzzyFilterPass,
    isFuzzyFilterPresent,
  ])

  return (
    <div
      // eslint-disable-next-line tailwindcss/no-unnecessary-arbitrary-value
      className="ag-theme-alpine size-full min-h-[600px] max-w-[100%]"
      id="HierarchyTree"
    >
      <div className="flex size-full flex-col rounded-2xs bg-background">
        <div className="flex items-center justify-between gap-xs rounded-t-2xs border border-b-0 border-solid border-border bg-background-hover px-s py-2xs">
          <div>
            <Text variant="description" bold>
              OPC-UA Hierarchy
            </Text>
          </div>
          <TextInput
            iconRight={light('search')}
            className="w-[200px]"
            placeholder="Search"
            variant="underlined"
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
          />
        </div>
        <div className="min-h-[2000px] w-full">{memoedTree}</div>
      </div>
    </div>
  )
}
